var initializedTimePicker = false;
var initializedMap = true;

$(function () {
    if ($('.rendez-vous').length) {
        initialize('rdv');
    }
});



function chosenDate(date, isInDetail) {
    "use strict";

    if (datehours.length == 0 || typeof datehours[date] == "undefined") {
        return;
    }
    selectedDate = date;

    if (typeof isInDetail == "undefined") {
        display_submit_section4(false);
    } else if (isInDetail) {
        $("#btn_change_appointment").css("display", "none");
    }

    var timeContent = "";

    for (var i = 0; i < datehours[date].length; i++) {
        var disabled = "";
        if (typeof isInDetail == "undefined") {
            var onClick = ' onclick="display_submit_section4(true);"';
        } else if (isInDetail) {
            var onClick =
                " onclick=\"$('#btn_change_appointment').css('display', 'block');\"";
        }

        if (!datehours[date][i]["isAvailable"]) {
            disabled = " disabled";
            onClick = "";
        }
        var hour_display = datehours[date][i]["hour"];
        hour_display = hour_display.replace(":", "h");
        hour_display = hour_display.replace("h00", "h");
        var hour_end_display = datehours[date][i]["hour_end"];
        hour_end_display = hour_end_display.replace(":", "h");
        hour_end_display = hour_end_display.replace("h00", "h");
        timeContent =
            timeContent +
            '<div><input type="radio" name="timeslot" id="timeslot_' +
            i +
            '" value="' +
            datehours[date][i]["hour"] +
            '"' +
            onClick +
            disabled +
            ' /><label for="timeslot_' +
            i +
            '"' +
            onClick +
            ">" +
            hour_display + ' - ' + hour_end_display +
            "</label></div>";
    }

    if (!initializedTimePicker) {
        initializedTimePicker = true;
    }

    $(".timePicker").html(timeContent);

    if (initializedTimePicker) {

        $(".timePickerWrapper").removeClass("disabled");
        $(".timePicker").addClass("actif");

    }

}

function getRdvAppointment(id, id_from) {
    "use strict";

    if (
        id === "weddingArea" &&
        typeof id_from != "undefined" &&
        id_from == "step3"
    ) {
        $(".formSubmit", ".step2").css("display", "none");
        $("#section4").hide();
        $("#step2").css("display", "none");
        $("#step3").css("display", "none");
    } else if (id === "step2") {
        var shopId = $("#btnStepTwo").attr("data-id");
        if (!!shopId === false) {
            return false;
        }
        $("#magNom")
            .find("#storeName")
            .html(
                $("#btnStepTwo")
                .parent()
                .find(".store")
                .find(".info_popup")
                .find(".name")
                .html()
            );
        $("#magAdd")
            .find("#storeAddress")
            .html(
                $("#btnStepTwo")
                .parent()
                .find(".store")
                .find(".info_popup")
                .find(".adresse")
                .html()
            );
        $("#magDate")
            .find("#dateMag")
            .html("");

        $("#magTime")
            .find("#timeMag")
            .html("");

        updateCalendar(shopId);
        if (typeof id_from != "undefined" && id_from == "step3") {
            $("#step3").css("display", "none");
        }
    } else if (id === "step4") {

        $("#step4").css("display", "block");
        var date = $("#datepicker").datepicker("getDate");
        var selectedDay = date.getUTCDay();
        var selectedMonth = date.getMonth();
        selectedHour = $("input[name=timeslot]:checked")[0].value;

        selectedDay = selectedDay + 1;

        if (selectedDay == 7) {
            selectedDay = 0;
        }
        var selectedDayName =
            jQuery.datepicker.regional["fr"].dayNames[selectedDay];
        var selectedMonthName =
            jQuery.datepicker.regional["fr"].monthNames[selectedMonth];

        $("#magDate")
            .find("#dateMag")
            .html(
                "Le " +
                selectedDayName +
                " " +
                date.getDate() +
                " " +
                selectedMonthName

            );

        $("#magTime")
            .find("#timeMag")
            .html(
                $("input[name=timeslot]:checked")
                .parent()
                .find("label")
                .html()
            );
    }

    var speed = 750;

    /*     if (id === "weddingArea") {
            var appointmentId = $("." + id);
        } else {*/

    var appointmentId = $("#" + id);
    $("#section4").show();

    appointmentId.slideDown(400);

    setTimeout(function () {
        var appointmentHeight = appointmentId.height();
        var appointmentSectionPos = appointmentId.offset();
        var windowHeight = $(window).innerHeight();
        var tagetTopPos =
            appointmentSectionPos.top +
            (appointmentHeight / 2 - windowHeight / 2);

        $("html, body").animate({
            scrollTop: tagetTopPos
        }, speed);
    }, 400);

    /* Hidding bottom selection for the final step */
    if (id === "step4") {
        $(".dressesSelected").removeClass("filled");
    } else if (
        typeof id_from != "undefined" &&
        id_from == "step3" &&
        nbAddedDresses > 0
    ) {
        $(".dressesSelected").addClass("filled");
    }
}

/**
 * [Anchor to desired section]
 */
function getSection(id) {
    "use strict";

    var weddingSection = $("#" + id);
    var sectionHeight = weddingSection.height();
    var sectionPos = weddingSection.offset();
    var windowHeight = $(window).innerHeight();
    var tagetTopPos = sectionPos.top + (sectionHeight / 2 - windowHeight / 2);
    var speed = 750;

    if (id === "section3") {
        $("html, body").animate({
            scrollTop: sectionPos.top
        }, speed);
    } else {
        $("html, body").animate({
            scrollTop: tagetTopPos
        }, speed);
    }

    return false;
}

var datehours = [];
var selectedShop = null;
var selectedDate = null;
var selectedHour = null;

function processDataCalendar(html) {
    datehours = [];
    if (typeof html == "string") {
        html = JSON.parse(html);
    }
    if (!!html == false) {
        return false;
    }
    datehours = html;
    disabledDays = [];
    for (var date in html) {
        if (html[date].length == 0) {
            disabledDays[disabledDays.length] = date;
        } else {
            var has_available_timeslot = false;
            for (var i = 0; i < html[date].length; i++) {
                if (html[date][i]["isAvailable"]) {
                    has_available_timeslot = true;
                }
            }
            if (!has_available_timeslot) {
                disabledDays[disabledDays.length] = date;
            }
        }
    }

    $("#datepicker")
        .datepicker("refresh")
        .find(".ui-state-active")
        .removeClass("ui-state-active");
}

function updateCalendar(id) {
    $.ajax({
        url: path_relative_root + "ajax_rdv_timeslot_list.php?shop=" + id,
        cache: false,
    }).done(function (html) {
        selectedShop = id;

        display_submit_section4(false);
        $(".timePickerWrapper").addClass("disabled");
        try {
            processDataCalendar(html);
        } catch (e) {

            return;
        }
    });
}

function weddingAlert(msgNum) {
    "use strict";

    var msgTranslate = translate(msgNum);

    if (msgNum === "product_already_added") {
        $(".changeSizeWrapper").show();
    }
    $('#shade.modal_shade').css('z-index', 10);
    $("#alertMsg").html(msgTranslate);
    openMultiShad("weddingAlert");
}

function scrollTo(target) {
    if (target.length) {
        $("html, body").stop().animate({
            scrollTop: target.offset().top
        }, 1500);
    }
}

function weddingValid() {
    $('#shade.modal_shade').css('z-index', 10);
    openMultiShad("weddingValid");

}

function confirm_rdv_appointment() {
    $("#confirmationLoader").addClass("loading");

    if (selectedShop == null) {
        $("#confirmationLoader").removeClass("loading");
        return false;
    }

    if ($("#clientTel").hasClass("inputErr")) {
        $("#confirmationLoader").removeClass("loading");
        return false;
    }

    $.ajax({
        type: "POST",
        url: path_relative_root + "ajax_rdv_create_order.php",
        data: {
            shop: selectedShop,
            date: selectedDate,
            hour: selectedHour,
            phone: $("#clientTel").val(),
        },
    }).done(function (msg) {
        if (msg == "ok") {
            /*  window.location.href =
                 path_relative_root + "espace-mariage-confirmation.php"; */
            weddingValid();
        } else {
            $("#confirmationLoader").removeClass("loading");
            if (!msg.match(/<+/)) {
                weddingAlert(msg);
            }
        }
    });

    return false;
}

/**
 * [Display final recap]
 */
function getRecap() {
    "use strict";
    var authWrapper = $(".authWrapper");
    var appointmentConf = $(".appointmentConfWrapper");

    authWrapper.slideUp(600, function () {
        appointmentConf.slideDown(800);
    });
}

function display_submit_section4(bool) {
    "use strict";

    $("#step3").css("display", "none");
    $(".dressesSelected").addClass("filled");

    if (bool) {
        $("#section4")
            .find(".formSubmit")
            .removeClass("cache");
    } else {
        $("#section4")
            .find(".formSubmit")
            .addClass("cache");
    }
}

function refreshTop() {
    (function (path_relative_root) {
        "use strict";

        function onMyAccountLoad() {
            var label, endWidth;

            this.target.innerHTML = this.render;

            label = document.getElementById("headerMyAccount");

            label.style.width = "auto";

            endWidth = getComputedStyle(label).width;

            label.style.width = 0;
            label.style.transition =
                "width 0.4s ease-out, opacity 0.4s linear 0.3s";

            var cart_unit = new UnitCounter(
                "cart_unit",
                0,
                Number(this.view_data.cart_amount_u)
            );
            var cart_cent = new UnitCounter(
                "cart_cent",
                0,
                Number(this.view_data.cart_amount_d),
                zeroPad
            );

            document.getElementById("cart_length").innerHTML =
                "( " + this.view_data.cart_length + " )";

            if (this.view_data.wishlist_length > 0) {
                document.getElementById(
                    "wishlist_length"
                ).innerHTML = this.view_data.wishlist_length;

                $("#btn_top_wishlist").slideDown(400);
            }

            window.setTimeout(function () {
                label.style.width = endWidth;
                label.style.opacity = 1;

                window.setTimeout(function () {
                    label.style.transition = "all 0 ease 0";
                    label.style.width = "auto";
                }, 700);
            }, 50);
        }

        function loadMyAccount() {
            var my_account = new Partial(
                "topMenuConnexionButton",
                path_relative_root + "my_account_data.php",
                "my_account_tmpl",
                onMyAccountLoad
            );
        }

        function loadPartials() {
            loadMyAccount();
        }
        loadPartials();

        getRdvAppointment("step4");
        $('.newlogin_page').hide();
    })(window.path_relative_root);
}

function refreshTopWedding() {
    getRdvAppointment("step4");
    $('.login_choices_container').hide();
}


/**
 * [Display lost password bloc]
 */
function displayLostMdp() {
    "use strict";

    var authForm = $("#auth");
    var lostMdp = $("#lostPwdWrapper");

    if (lostMdp.css("display") === "none") {
        authForm.slideUp(400, function () {
            lostMdp.slideDown(800);
        });
    } else {
        lostMdp.slideUp(400, function () {
            authForm.slideDown(800);
        });
    }
}
/**
 * [Reload bloc login]
 */
function reloadLoginBloc() {
    "use strict";

    var authForm = $("#auth");
    var lostMdp = $("#lostPwdWrapper");

    lostMdp.slideUp(400, function () {
        authForm.slideDown(800);
    });
}


// Auto select Country 