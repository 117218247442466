$(function() {
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (header.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    header.classList.remove("sticky");
                    shade.classList.remove("sticky");
                }
            } else {
                if (scrollTop !== 0) {
                    header.classList.add("sticky");
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    
    /*MODULE COVER*/
    if ($("#coverSwiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper("#coverSwiper", {
            slidesPerView: 1,
            speed: 500,
            autoplay: {
                delay: 10000,
            },
        });
    }

    /*MODULE MULTIPLE BLOC SLIDER*/
    if ($("#coverMultiple .swiper-slide").length > 3) {
        promoSwiper = new Swiper("#coverMultiple", {
            slidesPerView: 'auto',
            spaceBetween: 35,
            autoplay: {
                delay: 5000,
            },
        });
    } else if($("#coverMultiple .swiper-slide").length <= 3){
        $("#coverMultiple").addClass('noSwiper');
    }

    var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(' ');
	var a = 0;

	$(window).scroll(function(){
		animate_numbers();
	});

	animate_numbers();

	function animate_numbers(){
		if($('.image-image-image').length>0){
			var oTop = $('.image-image-image').offset().top - window.innerHeight;
			if (a == 0 && $(window).scrollTop() > oTop) {
				/* Number increasing effect */
				$('.image-image-image .home-module-number .number').each(function (){
					$(this).prop('number', 0).animateNumber(
					  {
					    easing: 'swing',
					    number: $(this).text(),
					    numberStep: comma_separator_number_step
					  },
					  2000
					);
				});
				a = 1;
			}
		}
	}
});